import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PaymentIcon from '@material-ui/icons/AttachMoney';
import LanguageIcon from '@material-ui/icons/Language';
import CarIcon from '@material-ui/icons/LocalShipping';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import { useHistory } from 'react-router-dom';
import { getEmpresa, PodeAcessarRelatorios, UsuarioComPermissao } from '../../Router/auth';
import { ReactComponent as localPlanejado } from '../../img/icon/localPlanejado_4.svg';
import { Roles } from '../../Utils/Roles';
import { Card, Divider, Typography } from '@mui/material';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import HandymanIcon from '@mui/icons-material/Handyman';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

export function MyCard(props) {
	const history = useHistory();
	const classes = useStyles();

	return (
		<Card
			className={classes.paper}
			onClick={() => { history.push(props.path); }}
			sx={{
				minWidth: "200px",
				"&:hover": {
					cursor: "pointer",
					backgroundColor: "#ede9e9",
				}
			}}
		>
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
			>
				<Grid item>
					{props.icon}
				</Grid>
				<Grid item>
					<Typography
						gutterBottom
						variant="h6"
						sx={{
							fontSize: "1.1rem",
							color: "rgba(0, 0, 0, 0.54)"
						}}
					>
						{props.text}
					</Typography>
				</Grid>
			</Grid>
		</Card>
	);
}

export default function Cadastros() {
	const classes = useStyles();

	const tamanhoIcone = 70;
	const styleicon = { fontSize: tamanhoIcone, color: "rgba(0, 0, 0, 0.54)" };
	const listaCadastros = [
		{
			titulo: "Cadastros",
			itens: [
				{ text: "Regiões", icon: <LanguageIcon style={styleicon} />, path: "/regioes", roles: [Roles.Master, Roles.Suporte] },
				{ text: "Usuários", icon: <PeopleAltIcon style={styleicon} />, path: "/usuarios", roles: [Roles.Master, Roles.Suporte, { role: Roles.Logistica, empresas: [69] }] },
				{ text: "Locais", icon: <SvgIcon component={localPlanejado} style={styleicon} />, path: "/locais", roles: [Roles.Master, Roles.Suporte, { role: Roles.Logistica, empresas: [69] }] },
				{ text: "Veículos", icon: <CarIcon style={styleicon} />, path: "/veiculos", roles: [Roles.Master, Roles.Suporte, { role: Roles.Logistica, empresas: [69] }] },
			]
		},
		{
			titulo: "Relatórios",
			itens: [
				...(PodeAcessarRelatorios() ? [{ text: "Relatórios", icon: <AssignmentIcon style={styleicon} />, path: "/relatorios" }] : []),
				{ text: "Download de Evidências", icon: <PhotoCameraOutlinedIcon style={styleicon} />, path: "/pacotesEvidencias", roles: [Roles.Suporte, Roles.Master] },
				...(getEmpresa().id === 57 ? [{ text: "Pagamentos", icon: <PaymentIcon style={styleicon} />, path: "/pagamentos", roles: [Roles.Master, Roles.Suporte] }] : [])
			]
		},
		{
			titulo: "Gestão de frota",
			itens: [
				{ text: "Abastecimentos", icon: <LocalGasStationIcon style={styleicon} />, path: "/abastecimento", roles: [Roles.Suporte, Roles.Master] },
				//{ text: "Manutenção", icon: <HandymanIcon style={styleicon} />, path: "/manutencao", roles: [Roles.Suporte, Roles.Master] },
			]
		}
	];

	console.log(listaCadastros)

	function FormRow() {
		var element = [];

		listaCadastros.forEach(grupo => {
			element.push(
				<Grid item xs={12}>
					<Typography variant='h6'>
						{grupo.titulo}
					</Typography>
				</Grid>
			);

			grupo.itens.forEach(x => {
				if (x.roles === undefined || (x.roles !== undefined && UsuarioComPermissao(x.roles))) {
					element.push(
						<Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
							<MyCard {...x}></MyCard>
						</Grid>
					);
				}
			})
		});

		return (
			<React.Fragment>
				{element}
			</React.Fragment>
		);
	}

	return (
		<div className={classes.root}>
			<Grid container item xs={12} spacing={3}>
				<FormRow />
			</Grid>
		</div>
	);
}
