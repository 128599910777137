import { getUser } from "../../Router/auth";
import React from 'react';
import { instance } from '../../API/axios';
import CardInformacoesComGrafico from './DashboardCardComGrafico'
import { VERMELHO_PASTEL, VERDE_PASTEL} from '../../Utils/ColorsGrafos';
import EntregaIcon from '../../Icons/EntregaIcon';

export default function Entrega(props) {
    const [info, setInfo] = React.useState("");
    const [tooltipText, setTooltipText] = React.useState("");
    const [barras, setBarras] = React.useState([]);
    
    React.useEffect(async () => {
        var filtro = {
            rotasIds: props.rotas.map(x => x.id),
            empresaId: getUser().empresaId,
        }

        var response = await instance.post("api/Dashboard/GetQuantEntregasByRotasIds", filtro);

        if(response.status == 200 && response.data.quantidadeTotalDeEntregas != 0){
            setInfo(response.data.quantidadeTotalDeEntregas);
            setBarras([
                {cor: VERDE_PASTEL, percentual: response.data.percentualDeEntregasRealizadas + "%", hideText: response.data.percentualDeEntregasRealizadas < 10 ? true : false},
                {cor: VERMELHO_PASTEL, percentual: response.data.percentualDeEntregasDevolvidas + "%", hideText: response.data.percentualDeEntregasDevolvidas < 10 ? true : false},
                {cor: 'rgb(187 187 187)', percentual: response.data.percentualDeEntregasAguardando + "%", hideText: response.data.percentualDeEntregasAguardando < 10 ? true : false}
            ])
            
            setTooltipText(`Entregues: ${response.data.quantidadeDeEntregasRealizadas} entregas (${response.data.percentualDeEntregasRealizadas}%)
            Devolvidas: ${response.data.quantidadeDeEntregasDevolvidas} entregas (${response.data.percentualDeEntregasDevolvidas}%)
            Aguardando: ${response.data.quantidadeDeEntregasAguardando} entregas (${response.data.percentualDeEntregasAguardando}%)

            Total: ${response.data.quantidadeTotalDeEntregas} entregas (100%)
            `)
        } else{
            setInfo("");
            setBarras([]);
        }
    }, [props.rotas]);

    return (
        <>
            <CardInformacoesComGrafico
                title={"Entregas totais"}
                info={info}
                backgroundColor={'rgb(187 187 187)'}
                icon={EntregaIcon}
                chartBars={barras}
                size={props.size}
                rotas={props.rotas}
                tooltipText={tooltipText}
            />
        </>
    );
}