import { Typography } from "@mui/material";
import { FieldType } from "../../Components/Form/common/FieldType";
import { tipoCombustivel } from "../../Enums/TipoCombustivel";

export const columnsGrid = [
    { label: "Veiculo", field: "placa", type: "customWithRow", function: getVeiculo },
    { label: "Data", field: "data", type: FieldType.DATETIME },
    { label: "Tipo combustível", field: "tipoCombustivel", type: "customWithRow", function: getTipoCombustivel },
    { label: "Quilometragem do veiculo (Km)", field: "kilometragemVeiculo", type: "number", align: "right" },
    { label: "Quantidade abastecida", field: "quantidadeAbastecida", type: "customWithRow", function: getQuantidadeAbastecida, align: "right" },
    { label: "Valor unitário", field: "valorPorUnidade", type: FieldType.MONEY, align: "right" },
    { label: "Valor total", field: "valorTotal", type: FieldType.MONEY, align: "right" },
    { label: "Media", field: "mediaKmPorUnidade", type: "customWithRow", function: getMedia, align: "right" },
    { label: "Valor por Km", field: "valorPorKm", type: FieldType.MONEY, align: "right" }
]

function getVeiculo(row) {
    return `${row.placa} ${row.apelido !== null && row.apelido !== "" ? `(${row.apelido})` : ""}`;
}

function getTipoCombustivel(row) {
    var tpCombustivel = "";

    switch (row.tipoCombustivel) {
        case tipoCombustivel.GasolinaComum:
            tpCombustivel = "Gasolina comum";
            break;
        case tipoCombustivel.GasolinaAditivada:
            tpCombustivel = "Gasolina aditivada";
            break;
        case tipoCombustivel.GasolinaPremium:
            tpCombustivel = "Gasolina premium";
            break;
        case tipoCombustivel.Etanol:
            tpCombustivel = "Etanol";
            break;
        case tipoCombustivel.GNV:
            tpCombustivel = "GNV";
            break;
        case tipoCombustivel.DieselS10:
            tpCombustivel = "Diesel S-10";
            break;
        case tipoCombustivel.DieselS500:
            tpCombustivel = "Diesel S-500";
            break;
        case tipoCombustivel.Biodiesel:
            tpCombustivel = "Biodiesel";
            break;
        case tipoCombustivel.Hidrogenio:
            tpCombustivel = "Hidrogênio";
            break;
        case tipoCombustivel.OVIN:
            tpCombustivel = "OVIN";
            break;
        case tipoCombustivel.Biometano:
            tpCombustivel = "Biometano";
            break;
        case tipoCombustivel.Arla:
            tpCombustivel = "Arla 32";
            break;
        case tipoCombustivel.Eletrico:
            tpCombustivel = "Elétrico";
            break;
        default:
            tpCombustivel = "";
            break;
    }

    return (
        <Typography>
            {tpCombustivel}
        </Typography>
    );
}

function getQuantidadeAbastecida(row) {
    let sufixo = "";

    switch (row.tipoCombustivel) {
        case tipoCombustivel.GasolinaComum:
        case tipoCombustivel.GasolinaAditivada:
        case tipoCombustivel.GasolinaPremium:
        case tipoCombustivel.DieselS10:
        case tipoCombustivel.DieselS500:
        case tipoCombustivel.Etanol:
        case tipoCombustivel.Biodiesel:
        case tipoCombustivel.OVIN:
            sufixo = "litros";
            break;
        case tipoCombustivel.GNV:
        case tipoCombustivel.Biometano:
            sufixo = "M³"
            break;
        case tipoCombustivel.Hidrogenio:
            sufixo = "kg";
            break;
        case tipoCombustivel.Eletrico:
            sufixo = "kWh";
            break
        default:
            sufixo = "";
            break;
    }

    return (
        <>{row.quantidadeAbastecida} {sufixo}</>
    )
}

function getMedia(row) {
    let sufixo = "";

    switch (row.tipoCombustivel) {
        case tipoCombustivel.GasolinaComum:
        case tipoCombustivel.GasolinaAditivada:
        case tipoCombustivel.GasolinaPremium:
        case tipoCombustivel.DieselS10:
        case tipoCombustivel.DieselS500:
        case tipoCombustivel.Etanol:
        case tipoCombustivel.Biodiesel:
        case tipoCombustivel.OVIN:
            sufixo = "l";
            break;
        case tipoCombustivel.GNV:
        case tipoCombustivel.Biometano:
            sufixo = "m³"
            break;
        case tipoCombustivel.Hidrogenio:
            sufixo = "kg";
            break;
        case tipoCombustivel.Eletrico:
            sufixo = "kWh";
            break
        default:
            sufixo = "";
            break;
    }

    if (row.mediaKmPorUnidade == null)
        return <></>

    return (
        <>{row.mediaKmPorUnidade} Km/{sufixo}</>
    );
}