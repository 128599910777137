import { instance_ms_frota } from "./axios_Frota";

export async function getAll(dataInicio, dataFim, veiculoIds) {
    try {
        const params = new URLSearchParams({
            ...(dataInicio != null && { dataInicio: dataInicio }),
            ...(dataFim != null && { dataFim: dataFim }),
        });

        if (veiculoIds && Array.isArray(veiculoIds)) {
            veiculoIds.forEach(id => params.append('veiculoIds', id));
        }

        const response = await instance_ms_frota.get(`/api/Abastecimento?${params}`);
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}