import { useCallback, useEffect, useState } from "react";
import { GridTable } from "../../Components/GridTable/GridTable";
import { columnsGrid } from "./Config";
import { getAll } from "../../API/Abastecimento";
import { getFormatDataToDataApi } from "../../Utils/FormatDateTime";
import { Box, Grid } from "@mui/material";
import DashboardCard from "../../Components/Dashboards/DashboardCard";
import { GRAY } from "../../Utils/ColorsGrafos";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FunctionsIcon from '@mui/icons-material/Functions';
import { KeyboardDatePicker } from "@material-ui/pickers";
import MultiplaSelecao from "../../Components/MultiplaSelecao/MultiplaSelecao";
import debounce from "lodash.debounce";

function AbastecimentoGridList() {
    const [dataInicio, setDataInicio] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [dataFim, setDataFim] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [veiculoIds, setVeiculoIds] = useState(null);
    const [rows, setRows] = useState([]);
    const [loadingRows, setLoadingRows] = useState(false);

    const [dashboardData, setDashboardData] = useState(null);

    const loadRows = useCallback(() => {
        setRows([]);
        setLoadingRows(true);

        getAll(getFormatDataToDataApi(dataInicio), getFormatDataToDataApi(dataFim), veiculoIds)
            .then(resp => {
                if (resp.data != null) {
                    setRows(resp.data);
                }
            })
            .catch(error => {

            })
            .finally(() => {
                setLoadingRows(false);
            });
    }, [dataInicio, dataFim, veiculoIds, setRows]);

    useEffect(() => {
        loadRows();
    }, [loadRows]);

    const debounceDataInicio = useCallback(debounce(setDataInicio, 500), []);
    const debounceDataFim = useCallback(debounce(setDataFim, 500), []);

    return (
        <>
            {/* por enquando nao mostra os cards */}
            <Grid sx={{ display: "none" }} container spacing={1}>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    <DashboardCard
                        title={"Valor total"}
                        info={dashboardData ? `R$ ${dashboardData.valorTotal}` : ""}
                        icon={<AttachMoneyIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    {/* aqui poderia ser um card com porcentagem de cada tipo de combustivel */}
                    <DashboardCard
                        title={"Total abastecido"}
                        info={"100 L"}
                        icon={<LocalGasStationIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    <DashboardCard
                        title={"Km Rodados"}
                        info={"1000 Km"}
                        icon={<LocalShippingIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    <DashboardCard
                        title={"Valor por Km"}
                        info={"0,36/Km"}
                        icon={<AttachMoneyIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    <DashboardCard
                        title={"Média"}
                        info={"10,5 Km/L"}
                        icon={<FunctionsIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    lg={2}
                    sm={2}
                    xl={2}
                >
                    <DashboardCard
                        title={"Preço medio combustivel"}
                        info={"R$ 6,56"}
                        fontSize={"18px"}
                        icon={<AttachMoneyIcon />}
                        size={4}
                        backgroundColor={GRAY}
                    />
                </Grid>
            </Grid>

            <GridTable
                title="Abastecimentos"
                rows={rows}
                columns={columnsGrid}
                rowsPerPage={50}
                atualizar={loadRows}
                loading={loadingRows}
                camposFiltros={
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <KeyboardDatePicker
                            showTodayButton
                            disableToolbar
                            autoOk
                            variant="inline"
                            onChange={debounceDataInicio}
                            label="Data inicial"
                            invalidDateMessage='Data invalida'
                            todayLabel='Hoje'
                            cancelLabel='Cancelar'
                            format="dd/MM/yyyy"
                            value={dataInicio}
                        />
                        <KeyboardDatePicker
                            showTodayButton
                            disableToolbar
                            autoOk
                            variant="inline"
                            onChange={debounceDataFim}
                            label="Data final"
                            invalidDateMessage='Data invalida'
                            todayLabel='Hoje'
                            cancelLabel='Cancelar'
                            format="dd/MM/yyyy"
                            value={dataFim}
                        />
                        <Box
                            sx={{
                                width: "300px",
                                height: "auto"
                            }}
                        >
                            <MultiplaSelecao
                                label="Veiculos"
                                placeholder="Selecione os veiculos"
                                localStorageName={"abastecimento_veiculos"}
                                endpoint="api/Veiculos/lookupMultiplo"
                                onChange={(ids) => { setVeiculoIds(ids) }}
                            />
                        </Box>
                    </Box>
                }
            />
        </>
    );
}

export default AbastecimentoGridList;