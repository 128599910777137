import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import TableToolbar from './TableToolbar';
import TableHead from './TableHead';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { DialogConfirm } from '../../Components/DialogConfirm/DialogConfirm';
import { getFormatDataTimeToString, getFormatDataToString } from '../../Utils/FormatDateTime';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {

  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export function GridTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ?? 10);
  const [openExcluir, setOpenExcluir] = React.useState(false);
  const [loadingExcluir, setLoadingExcluir] = React.useState(false);

  useEffect(() => {
    if (props.rows.length === 0) {
      if (props.filtrarDuranteSelecao !== true)
        setSelected([]);
    }
  }, [props.rows])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);
  const history = useHistory();

  const handleClickOpen = () => {
    setOpenExcluir(true);
  };

  const handleClose = () => {
    setOpenExcluir(false);
  };

  const handleClickExcluir = async () => {
    setLoadingExcluir(true);
    await props.excluirClick(selected);
    setOpenExcluir(false);
    setLoadingExcluir(false);
    setSelected([]);
  };

  const getField = (row, field) => {
    var campos = field.split('.');
    var rowX = row;
    for (let index = 0; index < campos.length; index++) {
      const element = campos[index];
      rowX = rowX[element];
    }
    return rowX;
  }

  return (
    <div className={classes.root}>
      <>
        <Typography style={{ paddingLeft: '10px', paddingTop: '10px', flex: '1 1 100%', paddingBottom: '20px' }} variant="h5" component="div">
          <b> {props.title}</b>
        </Typography>
      </>
      <Paper className={classes.paper}>
        <TableToolbar
          numSelected={selected.length}
          selected={selected}
          title={props.title}
          titleNovo={props.titleNovo}
          LinkNew={props.LinkNew}
          TemFiltro={props.TemFiltro}
          filtrar={props.filtrar}
          atualizar={props.atualizar}
          CanRemove={props.CanRemove}
          clickExcluir={handleClickOpen}
          filtrarDuranteSelecao={props.filtrarDuranteSelecao}
          actions={props.actions}
          actionsWhenSelect={props.actionsWhenSelect}
          actionsWhenSelectAposFiltro={props.actionsWhenSelectAposFiltro}
          camposFiltros={props.camposFiltros} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            size='small'
          >
            <TableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
              columns={props.columns}
            />
            <TableBody>
              {!props.loading && stableSort(props.rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onDoubleClick={() => {
                        history.push((props.LinkEditFieldId ? props.LinkEdit + row[props.LinkEditFieldId] : props.LinkEdit + row.id));
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >

                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      {
                        props.columns.map((column, i) => {
                          const field = getField(row, column.field);

                          switch (column.type) {
                            case 'bool':
                              return <TableCell component="th" id={labelId} scope="row" {...column}>
                                {field ? 'Sim' : 'Não'}
                              </TableCell>
                            case 'boolWithIcon':
                              return (
                                <TableCell component="th" id={labelId} scope="row" {...column}>
                                  {field ? <CheckCircleOutlineOutlinedIcon style={{ color: 'green' }} />
                                    : <HighlightOffIcon style={{ color: 'red' }} />}
                                </TableCell>
                              );
                            case 'option':
                            case 'lookup':
                              var t = column.options.filter(function (item) {
                                return item.value === field;
                              });

                              return <TableCell component="th" id={labelId} scope="row" {...column}>
                                {t.length > 0 ? t[0].label : ''}
                              </TableCell>
                            case 'date':
                              var data = new Date(field)
                              if (data.getFullYear() === 1 || field == null)
                                data = '';
                              else
                                data = getFormatDataToString(data);

                              return <TableCell component="th" id={labelId} scope="row" {...column}>
                                {data}
                              </TableCell>
                            case 'custom':
                              return <TableCell component="th" id={labelId} scope="row" {...column}>
                                {column.function(field, history)}
                              </TableCell>
                            case 'customWithRow':
                              return <TableCell component="th" id={labelId} scope="row" {...column} >
                                {column.function(row, history)}
                              </TableCell>
                            case 'datetime':
                              data = new Date(field)

                              if (data.getFullYear() === 1 || field == null)
                                data = '';
                              else
                                data = getFormatDataTimeToString(data);

                              return (
                                <TableCell component="th" id={labelId} scope="row" {...column}>
                                  {data}
                                </TableCell>
                              );
                            case 'money':
                              return (
                                <TableCell component="th" id={labelId} scope="row" {...column}>
                                  {field != null ? Number(field).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ""}
                                </TableCell>
                              );
                            case 'number':
                              return (
                                <TableCell component="th" scope="row" {...column}>
                                  {Number(field).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}
                                </TableCell>
                              );
                            default:
                              return (
                                <TableCell component="th" id={labelId} scope="row" padding={column.padding} {...column}>
                                  {field}
                                </TableCell>
                              );
                          }
                        })
                      }
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={12} >
                    {props.loading && (<div style={{ textAlign: 'center' }}><CircularProgress /></div>)}
                    {!props.loading && props.rows.length === 0 && (<><Typography align="center" variant="subtitle1" id="tableTitle" component="div">
                      Nenhum registro encontrado.
                    </Typography></>)}
                  </TableCell>
                </TableRow>
              )}

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Registros por página"
          labelDisplayedRows={(x) => { return x.from + "-" + x.to + " de " + x.count }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <DialogConfirm title={'Deseja excluir ' + selected.length + ' registros?'} text="Atenção: Essa ação não tem como ser revertida." open={openExcluir}
        button1Text="Cancelar" onClickButton1={handleClose}
        button2Text="Excluir" onClickButton2={handleClickExcluir} button2Loading={loadingExcluir} />
    </div>
  );
}
